<template>
  <div class="about-us">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div data-v-4e3aa79e="" id="content" class="content box" style="min-height: 581px;">
      <div data-v-4e3aa79e="" class="logo"><img data-v-4e3aa79e=""
                                                src="http://www.vrcs365.com/uploads/20181119/13e2f261c6a929cad7e159d7ffad8a4b.jpg">
      </div>
      <div data-v-4e3aa79e="" class="paragraph"><p style="text-indent: 37px;"><span
        style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);"> 多模态儿童神神经生长发育项目，由苏州大学杨敦雄博士整体设计与执行，整合海峡两岸教育、心理和康复领域专家共同发起推进的项目，以通过专业协助与资源整合，为儿童与家庭带来喜乐和平安，促进生命尊重与社会和谐为企业宗旨。项目组以苏州大学校训“养天地正气，法古今完人”为主旨，开展方案推广、专业训练、家庭及个案服务、学术及实务交流为工作重点，用现代的科学管理方式提升大学社会服务的信度与效度。协助各类发展迟缓儿童及其家庭进行早期疗育工作，向早期疗育相关人员提供训练课程（包括康复专业人员、家长领域，儿童领域，以及特教从业人员的职前职中培训及职后的继续教育培训），促进国内外发展障碍儿童早期疗育各专业人员的业务交流联谊会和跨专业个案讨论研讨会的达成。</span>
      </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">&nbsp;</span>
        </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">  

       此模式的特点乃是奠基于「个案管理」的原理，每一位接受康复或特殊教育的儿童，均有一位主责的专业人员担任他的家庭干预负责人(Primary _Service)以「儿童最近发展区」为前提，深入分析基因,神经激素,脑发育和教育心理的联合作用。在设计干预策略时会AI辅助下共同召开跨专业「个案讨论」（case discussion）的队伍，共同拟订或检验该儿童的个性化教育方案(IEP)或个性治疗目标(ISP)，同时开展亲职压力疏导。

       儿童服务项目包括：儿童神经类型测验、基因测序、静息态脑电、静息态心电、眼动轨迹、姿态传感和、AI辅助测评和人机交互。并囊括以下内容：</span>
        </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">&nbsp;</span>
        </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">1、儿童在线筛查工具;

      提供医疗机构、幼儿园或合作单位儿童观体检和筛查服务，受训过的人员通过基础体检、测量、检测和使用筛查量表初步筛查0-7岁儿童健康和发展状况，早期发现疑似健康问题和生长发育迟缓的儿童。</span>
        </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">&nbsp;</span>
        </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">2、AI辅助的功能性评估；

      体检或筛查出有疑似健康问题和生长发育迟缓的儿童，由保健医生、儿童健康管理师及治疗师（职能、物理、语言、心理师）使用标准化评估工具精准评估孩子的健康与生长发育状况，出具完整的评估报告，并且提供家长该儿童后续康复指导建议与干预计划。</span>
        </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">&nbsp;</span>
        </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">3、计算机自适应制定个人化干预计划及目标；

      以家庭为中心为中心的跨专业模式「PSP模式」、为孩子订定个性化的保健和干预计划及目标(在幼儿园即为融合教育的支持模式)。</span>
        </p>
         <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);"> <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">3、计算机自适应制定个人化干预计划及目标；

      以家庭为中心为中心的跨专业模式「PSP模式」、为孩子订定个性化的保健和干预计划及目标(在幼儿园即为融合教育的支持模式)。</span>
        </p></span>
        </p>
        <!-- <p><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">&nbsp;</span>
        </p>
        <p style="line-height: 16px;"><span style="color: rgb(63, 63, 63);"><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px;"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑;">网站：</span></strong></span><span
          style="text-decoration: underline; color: rgb(5, 99, 193); font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px;"><a
          href="http://www.xarw365.com"
          target="_blank"
          style="text-decoration: underline; color: rgb(5, 99, 193); font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px;">www.xarw365.com</a></span></span>
        </p>
        <p style="line-height: 16px;"><br></p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;">Email:xarw365@163.com</span></strong></span>
        </p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><br></span></strong></span></p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><span
          style="color: rgb(63, 63, 63); font-size: 18px; font-family: 微软雅黑;">订阅号：</span>xarw</span></strong><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;">365</span></strong></span></p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><br></span></strong></span></p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><span
          style="color: rgb(63, 63, 63); font-size: 18px; font-family: 微软雅黑;">服务号：</span>xarw</span></strong><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;">fw365</span></strong></span></p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><br></span></strong></span></p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><span
          style="color: rgb(63, 63, 63); font-size: 18px; font-family: 微软雅黑;">大龄服务号：</span>vrcs365</span></strong></span>
        </p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><br></span></strong></span></p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><span
          style="color: rgb(63, 63, 63); font-size: 18px; font-family: 微软雅黑;">地址：江苏省苏州市姑苏区东吴北路288号</span></span></strong></span>
        </p>
        <p style="line-height: 16px;"><br></p> -->
        <p><br></p></div>
      <!-- <h2>服务项目包括</h2> -->
      <!-- <ul class="service-option">
        <li>
          <div class="icon icon-aboutus1"></div>
          <p>亲职教育讲座、家长支持团体运作、家长辅助、亲师沟通技巧。</p>
        </li>
        <li>
          <div class="icon icon-aboutus2"></div>
          <p>儿童发展评估、儿童生活职能学习、早起疗育、融合教育、儿童全人教育倡导。</p>
        </li>
        <li>
          <div class="icon icon-aboutus3"></div>
          <p>身心障碍者职业培训、身障者职业重建。</p>
        </li>
        <li>
          <div class="icon icon-aboutus4"></div>
          <p>家庭社会工作、个案管理、社工实习、关怀专线、权益倡导等。</p>
        </li>
        <li>
          <div class="icon icon-aboutus5"></div>
          <p>心理咨询、疗育咨询、特殊教育、儿童康复等课程培训。</p>
        </li>
      </ul> -->
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import HeaderNav from '@/components/HeaderNav'
  import FooterNav from '@/components/FooterNav'

  export default {
    name: 'aboutus',
    data() {
      return {
        current: 'aboutus',
        logo: '',
        aboutUs: {
          profile: '',
          service: ''
        }
      }
    },
    mounted: function () {
      var div = document.getElementById('content')
      var h = document.documentElement.clientHeight || document.body.clientHeight
      div.style.minHeight = h - 40 + 'px'
    },
    components: {
      HeaderNav,
      FooterNav
    },
    created() {
      // 获取用户信息
    }
  }
</script>

<style lang="less" scoped>
  @import url('../assets/css/page/aboutus');
</style>
